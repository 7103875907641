jQuery(function () {
    setWindowSize()
    $(window).on('resize', function () {
        setWindowSize()
    })
    $(".spf-color").addClass("color-{{ $channel }}")
    $('#left-side-panel-close').on('click', function () {
        if ($("#spf-sidebar").width() > 100) $("#left-side-panel-close").html("&raquo;")
        else $("#left-side-panel-close").html("&laquo;")
        $('#spf-sidebar').toggleClass('active')
        $('.spf-item').toggleClass('spf-thin')
        $('.spf-item-description').toggleClass('hidden')
        $('#cpg-content').toggleClass('wide')
    })

    // contracts.blade.php
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    })
    $(".settings").on("change keyup", function () {
        $("#save-changes").show()
    })
    $(".checkmark-item").on("click", function () {
        $("#save-changes").show()
    })


    // selector.blade.php
    if ($("#accountModal").length > 0) { $("#accountModal").modal() }
    $("#account").on("change", function () {
        $("#accountSelector").submit()
    })

    // implement/list.blade.php
    $("#add-new").on("click", function () {
        clearForm()
        $("#activity_date").val("<?php echo date('m/d/Y'); ?>")
        $("#outletState").val("{{ $account->state}}")
        $("#outletType").val("XX")
        $("#activityModal").modal()
    })

    // genders
    // $('#gender_dropdown').on('change', function() {
    //    if ($(this).val() === "I don't see my gender category") {
    //        $('#gender_override').val('').removeClass('hidden');
    //        $('#gender').val('');
    //    } else {
    //        $('#gender_override').val('').addClass('hidden');
    //        $('#gender').val($(this).val());
    //    }
    // });
    //
    // $('#gender_override').on('change', function() {
    //     $('#gender').val($(this).val());
    // });

    // Meeting type
    $('#meeting-form-container #type').on('change', function () {
        if ($(this).val() === 'Standing Committee Meeting') {
            $('#meeting-form-container #committee').val('').removeClass('hidden')
        } else {
            $('#meeting-form-container #committee').val('').addClass('hidden')
        }

        if ($(this).val() === 'Ad-hoc workgroup Meeting') {
            $('#meeting-form-container #workgroup').val('').removeClass('hidden')
        } else {
            $('#meeting-form-container #workgroup').val('').addClass('hidden')
        }
    });

    $('form:not(.undisabled)').submit(function(e) {
        $(this).find("button[type='submit']").prop('disabled',true);
    });
})

function clearFormFields(form) {
    $("#" + form).find("input").val("")
    $("#" + form).find("textarea").val("")
    $("#" + form).find("select").val("XX")
    $("#" + form).find("input[type=checkbox]").prop("checked", false)
}

function setWindowSize() {
    window.pageWidth = $(window).outerWidth(true)
    window.pageHeight = $(window).outerHeight() - 138
    $("#page-content").css("height", window.pageHeight)
}
